a.navlink {
  padding: 0.5rem 1rem;
  cursor: pointer;
  font-weight: bold;
  text-decoration: none;
  color: rgba(255, 255, 255, 0.6);

  &.active {
    border-bottom: none;
    color: #17a2b8;
  }

  &:hover {
    color: #17a2b8;
  }
}

#hero {
  position: relative;
  height: 100vh;

  img {
    display: block;
    padding: 40px 0;
    max-width: 100%;
  }
}

#hero .center {
  color: white;
  text-align: center;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}
#hero .bg-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  z-index: -101;
}

#hero .bg-video #player {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  overflow: hidden;
  position: absolute;
  z-index: -102;
}

#hero .bg-video .overlay {
  background-color: rgba(0, 0, 0, 0.75);
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -100;
}

#menu {
  padding: 105px 0;
}

#contact {
  min-height: 100vh;
  padding: 105px 0;
}

#menu,
#contact {
  background-color: #fff;
}

#history {
  padding: 105px 0;
  h2 {
    color: white;
  }
}

.txtCenter {
  text-align: center;
}

#menu {
  h2 {
    display: inline;
  }
  a {
    margin: 10px;
    vertical-align: baseline;
  }
  .card {
    width: 185px;
  }
  .card-footer {
    padding: 0;
  }
}

a.phone {
  font-size: 1.5em;
  color: black;
  text-decoration: none;

  &:hover {
    color: rgba(0, 0, 0, 0.7);
  }
}

object {
  width: 100%;
  height: 85vh;
}
